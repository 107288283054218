<template>
  <section>
    <b-container fluid class="px-1">
      <b-card class="my-2 mx-1">
        <b-row align-v="center">
          <b-col cols="2">
            <p class="h5 mb-0" title="Numero di videotags">
              <b-icon icon="play-fill"></b-icon> {{ videotags_count }}
            </p>
          </b-col>
          <b-col cols="2">
            <p class="h5 mb-0" title="Numero di eventi">
              <b-icon icon="shield-shaded"></b-icon> {{ partite }}
            </p>
          </b-col>
          <b-col cols="2">
            <p class="h5 mb-0" title="Tempo totale videotags">
              <b-icon icon="watch"></b-icon> {{ videotags_time }}
            </p>
          </b-col>
          <b-col cols="3"></b-col>
          <b-col cols="3">
            <b-form-select
              v-model="utente"
              v-if="auth.isUserEnable('videotag_utenti')"
            >
              <option value="0">-- Utenti --</option>
              <option v-for="item in utenti" :value="item.id" :key="item.id">
                {{ item.nomeCompleto }}
              </option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-card>

      <h5 class="mt-2 ml-1">Videotag</h5>
      <b-container fluid>
        <b-row>
          <b-col cols="7" class="px-1">
            <p v-if="utente == 0">
              Filtra i videotag del tuo archivio personale
            </p>
            <p v-if="utente != 0">
              Filtra i videotag dell'archivio personale di
              <strong class="color-rosso">{{ labelUtente }}</strong>
            </p>
            <b-form @submit.stop.prevent="filtraArchivio()">
              <b-form-row>
                <b-col
                  v-for="(filter, filterName, i) in filters"
                  :key="`filter-${i}`"
                  cols="2"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="selectedFields[filterName]"
                      style="font-size: 0.9rem"
                    >
                      <template slot="first">
                        <option value="">{{ filter.name }}</option>
                      </template>
                      <template v-if="valueIsArray(filter.values)">
                        <option
                          v-for="(value, i) in filter.values"
                          :key="`value-${i}`"
                          :value="value.id"
                        >
                          {{ value.name }}
                        </option>
                      </template>
                      <template v-else>
                        <optgroup
                          v-for="(values, labelName, i) in filter.values"
                          :key="`group-${i}`"
                          :label="labelName"
                        >
                          <option
                            v-for="(value, j) in values"
                            :key="`value-${j}`"
                            :value="value.id"
                          >
                            {{ value.name }}
                          </option>
                        </optgroup>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group>
                    <b-form-input
                      v-model="selectedFields.keyword"
                      type="text"
                      placeholder="Parola chiave"
                      style="font-size: 0.9rem"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="1" class="col-submit">
                  <b-form-group>
                    <b-button
                      type="submit"
                      variant="success"
                      style="font-size: 0.9rem"
                      title="Cerca"
                      ><b-icon icon="search"></b-icon>
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col cols="1" class="col-submit">
                  <b-form-group>
                    <b-button
                      variant="danger"
                      @click.prevent="reset()"
                      style="font-size: 0.9rem"
                      title="Cancella"
                      ><b-icon icon="x-circle"></b-icon>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
          <b-col class="pb-3 px-1" align-self="end">
            <b-row>
              <b-col align-self="end">Elenco video</b-col>
              <b-col
                class="text-right"
                v-if="auth.isUserEnable('video_down_clip')"
              >
                <b-btn
                  variant="success"
                  class="mb-2"
                  style="width: 100%"
                  size="sm"
                  @click="archivioDownloads"
                >
                  Cartelle <b-icon icon="download"></b-icon
                ></b-btn>
                <b-btn
                  variant="success"
                  style="width: 100%"
                  size="sm"
                  @click="videotagDownloads"
                >
                  Videotag <b-icon icon="download"></b-icon
                ></b-btn>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="pb-3 px-1" align-self="end">
            <b-row>
              <b-col align-self="end">Gestione cartelle</b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7" class="px-1">
            <b-img
              v-if="showImageEditor"
              style="position: absolute; top: 0; z-index: 2"
              :src="imageEditor"
              :width="widthEditor"
              :height="heightEditor"
            />
            <video
              id="video-wrapper"
              class="video-js vjs-default-skin"
              controls
              :data-setup="setup"
            ></video>
          </b-col>
          <v-infinite-scroll
            :loading="loadingArchivio"
            @bottom="nextPage"
            :offset="20"
            style="max-height: 60vh; overflow-y: scroll"
            class="col px-1"
          >
            <b-card
              no-body
              v-for="video in videos"
              class="mb-1 p-2"
              :key="'video_' + video.id"
              :style="
                'background:' + video.videotags[0].privato ? 'yellow' : 'red'
              "
              :bg-variant="video.videotags[0].privato ? 'info' : 'default'"
            >
              <b-row style="cursor: pointer" class="pl-3">
                <b-col cols="11" class="px-0" v-b-toggle="'video_' + video.id">
                  <div v-if="video.tipo == 'wyscout' && video.partita_id">
                    <div>{{ partitaLabel(video.partita.squadre) }}</div>
                    <small class="text-muted"
                      >{{ video.partita.competizione.nome }} <br />
                      {{ video.partita.data | formatDateHour }}</small
                    >
                  </div>
                </b-col>
                <b-col class="px-0" v-b-toggle="'video_' + video.id">
                  <span class="when-open"
                    ><b-icon icon="caret-down-fill"></b-icon
                  ></span>
                  <span class="when-closed">
                    <b-icon icon="caret-right-fill"></b-icon
                  ></span>
                </b-col>
              </b-row>
              <b-collapse :id="'video_' + video.id">
                <draggable
                  :list="video.videotags"
                  :group="{ name: 'videotag', pull: 'clone', put: false }"
                  :sort="false"
                  :disabled="utente > 0 && !auth.isAdmin()"
                >
                  <div
                    v-for="(videotag, index) in video.videotags"
                    :key="'tag_' + videotag.id"
                  >
                    <small
                      ><strong>{{ soggettoLabel(videotag) }}</strong></small
                    ><br />
                    <small>{{ azioniLabel(videotag.azioni) }}</small>
                    <b-card
                      no-body
                      :class="[
                        'mb-1 p-2 default',
                        videotag.peso,
                        tags_cartelle_ids.indexOf(videotag.id) > -1
                          ? 'evidenziato'
                          : '',
                      ]"
                    >
                      <b-row align-v="center">
                        <b-col
                          cols="1"
                          class="pl-2 pr-1"
                          @click="playClip(videotag)"
                        >
                          <b-icon icon="play-fill"></b-icon>
                        </b-col>
                        <b-col
                          cols="5"
                          class="px-1"
                          @click="playClip(videotag)"
                        >
                          <small
                            >{{ videotag.inizio }} - {{ videotag.fine }}</small
                          >
                        </b-col>
                        <b-col class="px-1">
                          <b-icon
                            icon="camera-reels-fill"
                            v-if="videotag.tactical"
                            title="Tactical"
                          ></b-icon>
                        </b-col>
                        <b-col class="px-1">
                          <b-icon
                            v-if="videotag.preferiti"
                            icon="heart-fill"
                          ></b-icon>
                        </b-col>
                        <b-col class="px-1">
                          <span
                            v-if="videotag.editors.length > 0"
                            :title="'Editors: ' + videotag.editors.length"
                          >
                            <b-icon icon="camera"></b-icon>
                          </span>
                        </b-col>
                        <b-col class="px-1">
                          <span
                            v-b-tooltip.hover
                            v-if="videotag.nota"
                            :title="videotag.nota"
                            ><b-icon icon="info-circle-fill"></b-icon
                          ></span>
                        </b-col>
                        <b-col
                          class="px-1"
                          @click="downloadTag(videotag)"
                          v-if="
                            auth.isUserEnable('video_down_clip') &&
                            !videotag.download &&
                            !videotag.clip
                          "
                        >
                          <span title="richiedi download tag">
                            <b-icon icon="download"></b-icon>
                          </span>
                        </b-col>
                        <b-col
                          class="px-1"
                          v-if="
                            auth.isUserEnable('video_down_clip') &&
                            videotag.clip
                          "
                        >
                          <a
                            :href="videotag.clip_url"
                            title="Download clip disponibile"
                            download
                            target="_blank"
                          >
                            <b-icon
                              style="color: yellow"
                              icon="camera-video-fill"
                            ></b-icon>
                          </a>
                        </b-col>
                        <b-col
                          class="px-1"
                          v-if="
                            auth.isUserEnable('video_down_clip') &&
                            videotag.download
                          "
                        >
                          <span title="Clip in preparazione">
                            <b-icon
                              icon="arrow-clockwise"
                              animation="spin"
                            ></b-icon>
                          </span>
                        </b-col>
                        <b-col
                          class="pr-3 pl-1"
                          @click="
                            deleteTag(videotag.id, video.videotags, index)
                          "
                          v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
                        >
                          <span title="elimina videotag"
                            ><b-icon icon="trash"></b-icon
                          ></span>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </draggable>
              </b-collapse>
            </b-card>
            <div v-if="loadingArchivio" align="center" style="width: 100%">
              <img :src="loadingImg" style="width: 120px" />
            </div>
          </v-infinite-scroll>
          <b-col v-if="loadingCartelle" class="text-center">
            <img :src="loadingImg" style="width: 120px" />
          </b-col>
          <b-col
            style="height: 60vh; overflow: auto"
            v-if="!loadingCartelle"
            class="px-1"
          >
            <b-row v-if="utente == 0 || (utente > 0 && auth.isAdmin())">
              <b-col> <small>Nuova cartella</small></b-col>
              <b-col class="text-right">
                <b-form-select
                  v-model="newCartella.riassunto"
                  size="sm"
                  class="mb-1"
                >
                  <option value="0">-- tipo --</option>
                  <option value="1">riassunto</option>
                  <option value="2">highlights</option>
                  <option value="3">sintesi</option>
                  <option value="4">goals</option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-input-group
              size="sm"
              class="mb-1"
              v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
            >
              <b-form-input
                id="newCartella"
                v-model="newCartella.nome"
              ></b-form-input>
              <b-input-group-append>
                <b-btn variant="primary" @click="creaCartella">Crea</b-btn>
              </b-input-group-append>
            </b-input-group>

            <draggable
              :list="cartelle"
              @change="updateCartelle"
              :disabled="utente > 0 && !auth.isAdmin()"
            >
              <b-card
                no-body
                v-for="(cartella, index) in cartelle"
                :class="[
                  'mb-1 p-2',
                  cartella.riassunto ? 'riassunto-' + cartella.riassunto : '',
                ]"
                :key="'cartella_' + cartella.id"
              >
                <b-row class="pl-3">
                  <b-col
                    class="px-0"
                    cols="8"
                    v-b-toggle="'cartella_' + cartella.id"
                    style="cursor: move"
                  >
                    <div>{{ cartella.nome }}</div>
                    <div>
                      <small>{{ durataCartella(cartella) }}</small>
                    </div>
                  </b-col>
                  <b-col class="px-0" v-b-toggle="'cartella_' + cartella.id">
                    <span class="when-open"
                      ><b-icon icon="caret-down-fill"></b-icon
                    ></span>
                    <span class="when-closed">
                      <b-icon icon="caret-right-fill"></b-icon
                    ></span>
                  </b-col>
                  <b-col
                    class="px-0"
                    cols="1"
                    @click="downloadCartella(cartella.id)"
                    v-if="
                      auth.isUserEnable('video_down_clip') &&
                      cartella.videotags.length > 0
                    "
                    style="cursor: pointer"
                  >
                    <span title="download cartella">
                      <b-icon icon="download"></b-icon>
                    </span>
                  </b-col>
                  <b-col
                    class="px-0"
                    cols="1"
                    @click="selezionaCartella(cartella)"
                    v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
                    style="cursor: pointer"
                  >
                    <span title="modifica cartella"
                      ><b-icon icon="pencil-square"></b-icon
                    ></span>
                  </b-col>
                  <b-col
                    class="px-0"
                    cols="1"
                    @click="deleteCartella(cartella.id, index)"
                    v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
                    style="cursor: pointer"
                  >
                    <span title="elimina cartella"
                      ><b-icon icon="trash-fill"></b-icon
                    ></span>
                  </b-col>
                </b-row>
                <b-row v-if="editCartella.id == cartella.id">
                  <b-col>
                    <b-row>
                      <b-col>
                        <small
                          ><span class="color-rosso"
                            >Modifica cartella</span
                          ></small
                        ></b-col
                      >
                      <b-col class="text-right">
                        <b-form-select
                          v-model="editCartella.riassunto"
                          size="sm"
                          class="mb-1"
                        >
                          <option value="0">-- tipo --</option>
                          <option value="1">riassunto</option>
                          <option value="2">highlights</option>
                          <option value="3">sintesi</option>
                          <option value="4">goals</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-input-group size="sm" class="mb-1">
                      <b-form-input
                        id="editCartella"
                        v-model="editCartella.nome"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-btn
                          variant="secondary"
                          @click="deselezionaCartella"
                          title="Annulla"
                        >
                          <b-icon icon="x-circle-fill"></b-icon>
                        </b-btn>
                        <b-btn
                          variant="primary"
                          @click="modificaCartella"
                          title="Modifica"
                          ><b-icon icon="pencil"></b-icon
                        ></b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-collapse :id="'cartella_' + cartella.id">
                  <draggable
                    :list="cartella.videotags"
                    :group="{ name: 'videotag' }"
                    :disabled="utente > 0 && !auth.isAdmin()"
                    style="min-height: 50px"
                    @change="changeTagsCartella($event, cartella)"
                  >
                    <div
                      v-for="(videotag, index) in cartella.videotags"
                      :key="'cvt_' + videotag.id"
                    >
                      <b-card
                        no-body
                        :class="['mb-1 p-2 default', videotag.peso]"
                      >
                        <b-row>
                          <b-col
                            cols="1"
                            class="pl-2"
                            @click="playClip(videotag)"
                          >
                            <b-icon icon="play-fill"></b-icon>
                          </b-col>
                          <b-col
                            cols="6"
                            class="px-1"
                            @click="playClip(videotag)"
                          >
                            <small
                              >{{ videotag.inizio }} -
                              {{ videotag.fine }}</small
                            >
                          </b-col>
                          <b-col class="px-1">
                            <span v-if="videotag.tactical" title="tactical">
                              <b-icon icon="camera-reels-fill"></b-icon>
                            </span>
                          </b-col>
                          <b-col class="px-1">
                            <span v-if="videotag.preferiti" title="preferiti"
                              ><b-icon icon="heart-fill"></b-icon
                            ></span>
                          </b-col>
                          <b-col class="px-1">
                            <span
                              v-if="videotag.editors.length > 0"
                              :title="'Editors: ' + videotag.editors.length"
                            >
                              <b-icon icon="camera"></b-icon>
                            </span>
                          </b-col>
                          <b-col class="px-1">
                            <span
                              v-b-tooltip.hover
                              v-if="videotag.nota"
                              :title="videotag.nota"
                              ><b-icon icon="info-circle-fill"></b-icon
                            ></span>
                          </b-col>
                          <b-col
                            class="pr-3 pl-1"
                            @click="
                              deleteTagCartella(
                                videotag.pivot.id,
                                videotag.id,
                                cartella.id,
                                index
                              )
                            "
                            v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
                          >
                            <span title="elimina videotag da cartella"
                              ><b-icon icon="trash-fill"></b-icon
                            ></span>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </draggable>
                </b-collapse>
              </b-card>
            </draggable>
          </b-col>
        </b-row>
        <b-row class="mt-2 text-right">
          <b-col>
            <b-badge
              class="mr-1"
              style="
                background: red;
                padding: 0.5em 0.7em;
                font-weight: normal;
                font-size: 85%;
              "
              >Riassunto</b-badge
            >
            <b-badge
              class="mr-1"
              style="
                background: green;
                padding: 0.5em 0.7em;
                font-weight: normal;
                font-size: 85%;
              "
              >Highlights</b-badge
            >
            <b-badge
              class="mr-1"
              style="
                background: blue;
                padding: 0.5em 0.7em;
                font-weight: normal;
                font-size: 85%;
              "
              >Sintesi</b-badge
            >
            <b-badge
              style="
                background: orange;
                padding: 0.5em 0.7em;
                font-weight: normal;
                font-size: 85%;
              "
              >Goals</b-badge
            >
          </b-col>
        </b-row>
      </b-container>
      <!--/div>
      </div-->
    </b-container>
    <b-modal
      size="lg"
      ref="downloadModal"
      title="Cartelle Downloads"
      ok-only
      ok-title="Chiudi"
    >
      <b-table
        :items="downloads"
        :fields="[
          { key: 'creato', formatter: this.formatDate },
          'file',
          'azioni',
        ]"
      >
        <template v-slot:cell(file)="row">
          {{ row.item.path ? row.item.path : "File non ancora disponibile!" }}
        </template>
        <template v-slot:cell(azioni)="row">
          <b-button
            size="sm"
            class="mr-2"
            variant="primary"
            title="download video"
            :href="row.item.video_clips_url"
            v-if="row.item.path"
            target="_blank"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
          <b-button
            size="sm"
            title="elimina video download"
            @click="eliminaVideo(row.item.id)"
            v-if="utente == 0 || (utente > 0 && auth.isAdmin())"
          >
            <b-icon icon="trash-fill"></b-icon>
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      size="lg"
      ref="tagDownloadModal"
      title="Videotag Downloads"
      ok-only
      ok-title="Chiudi"
    >
      <b-table
        :items="videotag_downloads"
        :fields="[
          { key: 'created_at', label: 'Creato', formatter: this.formatDate },
          'tipotag',
          'soggetto',
          'azioni',
        ]"
      >
        <template v-slot:cell(soggetto)="row">
          {{
            row.item.giocatore
              ? row.item.giocatore.cognome
              : row.item.allenatore
              ? row.item.allenatore.cognome
              : row.item.squadra
              ? row.item.squadra.name
              : ""
          }}
        </template>
        <template v-slot:cell(azioni)="row">
          <b-button
            size="sm"
            class="mr-2"
            variant="primary"
            title="download clip"
            :href="row.item.clip_url"
            target="_blank"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
        </template>
      </b-table>
    </b-modal>
  </section>
</template>

<script>
import Auth from "@/lib/auth.js";
import videojs from "video.js";
import "videojs-errors";
import "videojs-youtube";
import draggable from "vuedraggable";
import moment from "moment";
import _ from "lodash";
import InfiniteScroll from "v-infinite-scroll";
import loadingImg from "@/assets/images/loading.gif";
import tagMixin from "@/mixins/tags.js";

export default {
  data() {
    return {
      loadingArchivio: false,
      loadingCartelle: false,
      page: 1,
      total: 0,
      count_tag: 0,
      tot_time_tag: 0,
      videos: [],
      cartelle: [],
      selectedFields: {
        playerOrTeam: "",
        action: "",
        player: "",
        team: "",
        coach: "",
        positiveOrNegative: "",
        defensiveOrOffensive: "",
        fieldSection: "",
        keyword: "",
        anno: "",
        mese: "",
      },
      newCartella: {
        nome: "",
        riassunto: 0,
      },
      editCartella: {
        id: 0,
        nome: "",
        riassunto: 0,
      },
      tags_cartelle_ids: [],
      giocatori: [],
      squadre: [],
      allenatori: [],
      skills: [],
      values: [],
      mesi: [],
      anni: [],
      utenti: [],
      utente: 0,
      mesiLabel: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      downloads: [],
      videotag_downloads: [],
      tipoPartita: ["Campionato", "Coppa", "Amichevole", "Torneo"],
      tipoAllenamento: [
        "Standard",
        "Perfezionamento",
        "Recupero",
        "Portieri",
        "Differenziato",
        "Match",
      ],
      loadingImg,
      errors_player: {
        4: {
          headline: "Video non disponibile",
          type: "",
          message:
            "La clip non è stata ancora generata.<br/>Riprova più tardi.",
        },
      },
      currentTag: null,
      editorShown: null,
      imageEditor: null,
      width: 700,
      height: 420,
      tactical_start_seconds: 0,
      tactical_end_seconds: 0,
    };
  },

  mixins: [tagMixin],

  components: {
    draggable,
    "v-infinite-scroll": InfiniteScroll,
  },
  computed: {
    auth: function () {
      return Auth;
    },
    filters() {
      return {
        playerOrTeam: {
          name: "Tipo Tag",
          values: [
            { id: 0, name: "Squadra" },
            { id: 1, name: "Giocatore" },
            { id: 2, name: "Allenatore" },
          ],
        },
        action: {
          name: "Azione",
          values: this.actionValues,
        },
        player: {
          name: "Giocatore",
          values: this.playerValues,
        },
        team: {
          name: "Squadra",
          values: this.teamValues,
        },
        coach: {
          name: "Allenatore",
          values: this.coachValues,
        },
        positiveOrNegative: {
          name: "Pos / Neg",
          values: [
            { id: 1, name: "Positivo" },
            { id: 0, name: "Negativo" },
          ],
        },
        defensiveOrOffensive: {
          name: "Def / Tr- / Off / Tr+",
          values: [
            { id: 0, name: "Difensivo" },
            { id: 2, name: "Transizioni-" },
            { id: 1, name: "Offensivo" },
            { id: 3, name: "Transizioni+" },
          ],
        },
        fieldSection: {
          name: "Sezione campo",
          values: [
            { id: 1, name: "Zona 1" },
            { id: 2, name: "Zona 2" },
            { id: 3, name: "Zona 3" },
            { id: 4, name: "Zona 4" },
            { id: 5, name: "Zona 5" },
            { id: 6, name: "Zona 6" },
            { id: 7, name: "Zona 7" },
            { id: 8, name: "Zona 8" },
            { id: 9, name: "Zona 9" },
            { id: 10, name: "Zona 10" },
            { id: 11, name: "Zona 11" },
            { id: 12, name: "Zona 12" },
            { id: 13, name: "Zona 13" },
          ],
        },
        anno: {
          name: "Anno",
          values: this.anniValues,
        },
        mese: {
          name: "Mese",
          values: this.mesiValues,
        },
      };
    },
    actionValues() {
      var only_root_skill = true;
      for (var i in Auth.user.rosaTeams) {
        var team = Auth.user.rosaTeams[i];
        if (!team.categoria.only_root_skill) {
          only_root_skill = false;
          break;
        }
      }
      var jsonObject = {};
      for (var k in this.skills) {
        var item = this.skills[k];
        if (item.fields) {
          for (var j in item.fields) {
            var item2 = item.fields[j];
            if (item2.fields) {
              var obj = { id: item2.id, name: item2.it };
              var array = [];
              if (typeof jsonObject[item.it] !== "undefined") {
                array = jsonObject[item.it];
              }
              array.push(obj);
              jsonObject = this.nest(jsonObject, [item.it], array);

              if (!only_root_skill) {
                for (var y in item2.fields) {
                  var item3 = item2.fields[y];
                  if (item3.fields) {
                    for (var x in item3.fields) {
                      var item4 = item3.fields[x];
                      var obj = {
                        id: item4.id,
                        name: item2.it + " -> " + item3.it + " -> " + item4.it,
                      };
                      var array = [];
                      if (typeof jsonObject[item.it] !== "undefined") {
                        array = jsonObject[item.it];
                      }
                      array.push(obj);
                      jsonObject = this.nest(jsonObject, [item.it], array);
                    }
                  } else {
                    var obj = {
                      id: item3.id,
                      name: item2.it + " -> " + item3.it,
                    };
                    var array = [];
                    if (typeof jsonObject[item.it] !== "undefined") {
                      array = jsonObject[item.it];
                    }
                    array.push(obj);
                    jsonObject = this.nest(jsonObject, [item.it], array);
                  }
                }
              }
            } else {
              var obj = { id: item2.id, name: item2.it };
              var array = [];
              if (typeof jsonObject[item.it] !== "undefined") {
                array = jsonObject[item.it];
              }
              array.push(obj);
              jsonObject = this.nest(jsonObject, [item.it], array);
            }
          }
        }
      }
      return jsonObject;
    },
    playerValues() {
      var values = [];
      for (var i in this.giocatori) {
        var giocatore = {
          id: this.giocatori[i].id,
          name: this.giocatori[i].cognome + " " + this.giocatori[i].nome,
        };
        values.push(giocatore);
      }
      return values;
    },
    teamValues() {
      var values = [];
      for (var i in this.squadre) {
        var squadra = {
          id: this.squadre[i].id,
          name: this.squadre[i].name,
        };
        values.push(squadra);
      }
      return values;
    },
    coachValues() {
      var values = [];
      for (var i in this.allenatori) {
        var allenatore = {
          id: this.allenatori[i].id,
          name: this.allenatori[i].cognome + " " + this.allenatori[i].nome,
        };
        values.push(allenatore);
      }
      return values;
    },
    anniValues() {
      var values = [];
      for (var i in this.anni) {
        var anno = {
          id: this.anni[i].anno,
          name: this.anni[i].anno,
        };
        values.push(anno);
      }
      return values;
    },
    mesiValues() {
      var values = [];
      for (var i in this.mesi) {
        var mese = {
          id: this.mesi[i].mese,
          name: this.mesiLabel[this.mesi[i].mese - 1],
        };
        values.push(mese);
      }
      return values;
    },
    setup() {
      return JSON.stringify({
        techOrder: ["html5"],
        width: "100%",
        height: "100%",
        aspectRatio: "16:9",
        youtube: {
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
      });
    },
    partite() {
      return this.total;
    },
    videotags_count() {
      return this.count_tag;
    },
    videotags_time() {
      return moment.utc(this.tot_time_tag * 1000).format("HH:mm:ss");
    },
    labelUtente() {
      for (var i in this.utenti) {
        if (this.utenti[i].id == this.utente) {
          return this.utenti[i].nomeCompleto;
        }
      }
      return null;
    },
    showImageEditor() {
      if (this.imageEditor) {
        return true;
      }
      return false;
    },
    widthEditor() {
      return this.width;
    },

    heightEditor() {
      return this.height;
    },
  },
  created: function () {
    this.loadArchivio();
    this.loadCartelle();
    this.loadFiltri();
    if (Auth.isUserEnable("videotag_utenti")) {
      this.loadUtenti();
    }
  },
  methods: {
    loadArchivio() {
      this.loadingArchivio = true;
      this.page = 1;
      this.videos = [];
      this.$http.get("/videotags/archivio/" + this.utente).then((response) => {
        this.videos = response.data.videos.data;
        this.total = response.data.videos.total;
        this.count_tag = response.data.videotags_count;
        this.tot_time_tag = response.data.videotags_time;
        this.loadingArchivio = false;
      });
    },
    nextPage() {
      if (this.videos.length < this.total) {
        this.page++;
        this.loadingArchivio = true;
        this.$http
          .get("/videotags/archivio/" + this.utente + "?page=" + this.page, {
            params: this.selectedFields,
          })
          .then((response) => {
            this.videos = this.videos.concat(response.data.videos.data);
            this.loadingArchivio = false;
          });
      }
    },
    loadCartelle() {
      this.loadingCartelle = true;
      this.tags_cartelle_ids = [];
      this.$http.get("/videotags/cartelle/" + this.utente).then((response) => {
        this.cartelle = response.data;
        for (var i in this.cartelle) {
          var cartella = this.cartelle[i];
          for (var x in cartella.videotags) {
            var videotag = cartella.videotags[x];
            this.tags_cartelle_ids.push(videotag.id);
          }
        }
        this.loadingCartelle = false;
      });
    },
    loadFiltri() {
      this.$http
        .get("/videotags/filtri/archivio/" + this.utente)
        .then((response) => {
          this.giocatori = response.data.giocatori;
          this.squadre = response.data.squadre;
          this.allenatori = response.data.allenatori;
          this.skills = response.data.skills;
          this.mesi = response.data.mesi;
          this.anni = response.data.anni;
        });
    },
    loadUtenti() {
      this.$http.get("/videotags/utenti").then((response) => {
        this.utenti = response.data;
      });
    },

    valueIsArray(value) {
      return Array.isArray(value);
    },

    nest: function (obj, keys, v) {
      if (keys.length === 1) {
        obj[keys[0]] = v;
      } else {
        var key = keys.shift();
        obj[key] = this.nest(
          typeof obj[key] === "undefined" ? {} : obj[key],
          keys,
          v
        );
      }

      return obj;
    },

    filtraArchivio() {
      this.loadingArchivio = true;
      this.page = 1;
      this.videos = [];
      this.$http
        .get("/videotags/archivio/" + this.utente, {
          params: this.selectedFields,
        })
        .then((response) => {
          this.videos = response.data.videos.data;
          this.total = response.data.videos.total;
          this.count_tag = response.data.videotags_count;
          this.tot_time_tag = response.data.videotags_time;
          this.loadingArchivio = false;
        });
    },
    reset() {
      this.selectedFields = {
        playerOrTeam: "",
        action: "",
        player: "",
        team: "",
        coach: "",
        positiveOrNegative: "",
        defensiveOrOffensive: "",
        fieldSection: "",
        keyword: "",
        anno: "",
        mese: "",
      };
    },
    partitaLabel(squadre) {
      if (squadre.length > 0) {
        if (squadre[0].pivot.side == "home") {
          return squadre[0].name + " - " + squadre[1].name;
        } else return squadre[1].name + " - " + squadre[0].name;
      } else {
        return "Home - Away";
      }
    },
    azioniLabel(azioni) {
      if (azioni.length > 0) {
        var nome = "";
        for (var i in azioni) {
          var azione = azioni[i];
          if (nome != "") {
            nome += " - ";
          }
          nome += azione.azione_nome;
        }
        if (nome == "") {
          return "Azione generica";
        }
        return nome;
      } else {
        return "Azione generica";
      }
    },
    soggettoLabel(videotag) {
      var soggetto = "";
      if (videotag.tipotag == "giocatore") {
        soggetto = videotag.giocatore.cognome + " " + videotag.giocatore.nome;
      } else if (videotag.tipotag == "squadra") {
        soggetto = videotag.squadra.name;
      } else if (videotag.tipotag == "allenatore") {
        soggetto = videotag.allenatore.cognome + " " + videotag.allenatore.nome;
      }
      return soggetto;
    },
    playClip(videotag) {
      this.editorShown = null;
      this.currentTag = videotag;
      this.$video.src({
        type: "video/mp4",
        src:
          this.$store.state.apiEndPoint +
          "/videotag/play/clip/" +
          videotag.id +
          "/" +
          Auth.user.id +
          "/" +
          Auth.user.api_token,
      });
      if (videotag.tactical) {
        this.tactical_start_seconds =
          (moment(videotag.inizio, "HH:mm:ss") -
            moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.tactical_end_seconds =
          (moment(videotag.fine, "HH:mm:ss") - moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.$video.currentTime(this.tactical_start_seconds);
      }
      this.$video.play();
    },
    deleteTag(id, videotags, index) {
      if (confirm("Sei sicuro di voler eliminare il tag?")) {
        this.$http.delete("/tag/" + id).then(
          (response) => {
            videotags.splice(index, 1);
          },
          (response) => {}
        );
      }
    },
    creaCartella() {
      if (this.newCartella.nome == "") {
        alert("Devi dare un nome alla cartella!");
        return;
      } else {
        this.$http
          .get("/videotags/add/cartella/" + this.utente, {
            params: this.newCartella,
          })
          .then((response) => {
            this.cartelle.push(response.data);
            this.newCartella = {
              nome: "",
              riassunto: 0,
            };
          });
      }
    },
    deleteCartella(id, index) {
      if (confirm("Sei sicuro di voler eliminare la cartella?")) {
        this.$http.get("/videotags/delete/cartella/" + id).then((response) => {
          var videotags = this.cartelle[index].videotags;
          for (var i in videotags) {
            var videotag = videotags[i];
            var idx = this.tags_cartelle_ids.indexOf(videotag.id);
            if (idx > -1) {
              this.tags_cartelle_ids.splice(idx, 1);
            }
          }
          this.cartelle.splice(index, 1);
        });
      }
    },
    selezionaCartella(cartella) {
      this.editCartella = {
        id: cartella.id,
        nome: cartella.nome,
        riassunto: cartella.riassunto,
      };
    },
    deselezionaCartella() {
      this.editCartella = {
        id: 0,
        nome: "",
        riassunto: 0,
      };
    },
    modificaCartella(id, index) {
      if (this.editCartella.nome == "") {
        alert("Devi dare un nome alla cartella!");
        return;
      } else {
        this.$http
          .get("/videotags/edit/cartella/" + this.editCartella.id, {
            params: this.editCartella,
          })
          .then((response) => {
            for (var i in this.cartelle) {
              if (this.cartelle[i].id == this.editCartella.id) {
                this.cartelle[i].nome = this.editCartella.nome;
                this.cartelle[i].riassunto = this.editCartella.riassunto;
                break;
              }
            }
            this.editCartella = {
              id: 0,
              nome: "",
              riassunto: 0,
            };
          });
      }
    },
    updateCartelle() {
      var ids_cartelle = [];
      for (var i in this.cartelle) {
        ids_cartelle.push(this.cartelle[i].id);
      }
      this.$http
        .get("/videotags/update/order/cartelle", { params: ids_cartelle })
        .then((response) => {});
    },

    changeTagsCartella(evt, cartella) {
      if (evt.added) {
        var videotags = [];
        for (var i in cartella.videotags) {
          videotags.push(cartella.videotags[i].id);
        }
        this.$http
          .get("/videotags/add/tag/cartella", {
            params: { cartella: cartella.id, videotags: videotags },
          })
          .then((response) => {
            for (var i in this.cartelle) {
              if (this.cartelle[i].id == cartella.id) {
                this.cartelle[i].videotags = response.data.videotags;
                break;
              }
            }
            this.tags_cartelle_ids.push(evt.added.element.id);
          });
      } else if (evt.removed) {
        this.$http
          .get("/videotags/del/tag/cartella/" + evt.removed.element.pivot.id)
          .then((response) => {
            var idx = this.tags_cartelle_ids.indexOf(evt.removed.element.id);
            if (idx > -1) {
              this.tags_cartelle_ids.splice(idx, 1);
            }
          });
      } else if (evt.moved) {
        var tagscartella = [];
        for (var i in cartella.videotags) {
          tagscartella.push(cartella.videotags[i].pivot.id);
        }
        this.$http
          .get("/videotags/update/order/tags/cartella", {
            params: { videotags: tagscartella },
          })
          .then((response) => {});
      }
    },
    deleteTagCartella(id, id_videotag, id_cartella, index) {
      if (confirm("Sei sicuro di voler eliminare il tag dalla cartella?")) {
        this.$http.get("/videotags/del/tag/cartella/" + id).then((response) => {
          for (var i in this.cartelle) {
            if (this.cartelle[i].id == id_cartella) {
              this.cartelle[i].videotags.splice(index, 1);
              break;
            }
          }
          var idx = this.tags_cartelle_ids.indexOf(id_videotag);
          if (idx > -1) {
            this.tags_cartelle_ids.splice(idx, 1);
          }
        });
      }
    },
    downloadTag(videotag) {
      this.$http.get("/tag/download/" + videotag.id).then((response) => {
        videotag.download = response.data.download;
        alert(
          "La tua clip sarà pronta a breve per il download. Fai il refresh della pagina per vederla disponibile!"
        );
      });
    },
    /*  downloadClip(id) {
      window.open(
        this.$store.state.apiEndPoint +
        "/videotag/download/clip/" +
        id +
        "/" +
        Auth.user.id +
        "/" +
        Auth.user.api_token
        );
      },  */
    downloadCartella(id) {
      this.$http.get("/videotags/download/cartella/" + id).then((response) => {
        alert(response.data.message);
      });
    },
    archivioDownloads() {
      this.$http
        .get("/videotags/archivio/downloads/" + this.utente)
        .then((response) => {
          this.downloads = response.data;
          this.$refs.downloadModal.show();
        });
    },
    videotagDownloads() {
      this.$http
        .get("/videotags/videotag/downloads/" + this.utente)
        .then((response) => {
          this.videotag_downloads = response.data;
          this.$refs.tagDownloadModal.show();
        });
    },
    formatDate(data) {
      var m = moment.utc(data).local();
      var format = m.format("DD/MM/YYYY HH:mm");
      return format;
    },
    /*  downloadVideo(video) {
      window.open(
        this.$store.state.apiEndPoint +
        "/videotags/download/video/" +
        video +
        "/" +
        Auth.user.id +
        "/" +
        Auth.user.api_token
        );
      }, */
    eliminaVideo(video) {
      if (confirm("Confermi l'eliminazione del video?")) {
        this.$http
          .get("/videotags/elimina/videodownload/" + video)
          .then((response) => {
            this.downloads = response.data;
          });
      }
    },
    durataCartella(cartella) {
      var time = 0;
      for (var x in cartella.videotags) {
        var videotag = cartella.videotags[x];
        time =
          time +
          (moment(videotag.fine, "HH:mm:ss") -
            moment(videotag.inizio, "HH:mm:ss"));
      }
      return moment.utc(time).format("HH:mm:ss");
    },

    onTimeUpdate() {
      if (this.currentTag && this.currentTag.editors.length > 0) {
        for (var i in this.currentTag.editors) {
          var editor = this.currentTag.editors[i];
          var time =
            (moment(editor.time, "HH:mm:ss") -
              moment(this.currentTag.inizio, "HH:mm:ss")) /
            1000;

          if (this.currentTag.tactical) {
            time = time + this.tactical_start_seconds;
          }

          if (
            editor.download &&
            this.formatDuration(time) ==
              this.formatDuration(this.$video.currentTime()) &&
            editor.id != this.editorShown
          ) {
            if (!this.$video.paused()) {
              this.editorShown = editor.id;
              this.$video.pause();
              this.width = this.$video.currentWidth();
              this.height = this.$video.currentHeight();
              this.imageEditor = editor.foreground_url;
              const seconds = editor.seconds;
              setTimeout(() => {
                this.toggleEditor();
              }, seconds * 1000);
            }
          }
        }
      }
      if (this.currentTag && this.currentTag.tactical) {
        if (this.$video.currentTime() >= this.tactical_end_seconds) {
          this.$video.pause();
        }
      }
    },
    toggleEditor() {
      this.imageEditor = null;
      this.$video.play();
    },
  },
  mounted() {
    this.$video = videojs("video-wrapper");
    this.$video.on("timeupdate", this.onTimeUpdate);
    //  this.video.errors();
    /*  this.$video.errors({
      errors: this.errors_player,
    }); */
  },
  beforeDestroy() {
    this.$video.off("timeupdate", this.onTimeUpdate);
    this.$video.dispose();
    this.$video = null;
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
  watch: {
    utente: function () {
      this.$video.pause();
      this.$video.reset();
      this.reset();
      this.loadArchivio();
      this.loadCartelle();
      this.loadFiltri();
    },
  },
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}

.default {
  cursor: pointer;
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

.positivo {
  background-color: rgba(55, 128, 70, 0.7);
}

.negativo {
  background-color: rgba(166, 15, 33, 0.7);
}

.riassunto-1 {
  border-color: red;
}

.riassunto-2 {
  border-color: green;
}

.riassunto-3 {
  border-color: blue;
}

.riassunto-4 {
  border-color: orange;
}

.evidenziato {
  border: 3px solid yellow;
}
</style>
